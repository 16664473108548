<!-- Why Choose Us Start -->
<section class="section" id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Kontakt mig i sager om...</h3>
          <p class="text-muted font-size-15">Jeg er forsvarer i alle slags sager, men her er mine spidskompetencer.</p>
        </div>
      </div>
    </div>


    <div class="row align-items-center">
      <div class="col-lg-4">
        <div class="mb-4 mb-lg-0">
          <div class="p-2 bg-soft-primary d-inline-block rounded mb-4">
            <div class="icon-xxl uim-icon-primary"><span class="uim-svg"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" width="1em">
                  <polygon class="uim-primary" points="12 12.3 3.5 7.05 12 1.8 20.5 7.05 12 12.3"></polygon>
                  <polygon class="uim-quaternary" points="12 22.2 12 12.3 20.5 7.05 20.5 16.95 12 22.2"></polygon>
                  <polygon class="uim-tertiary" points="12 22.2 3.5 16.95 3.5 7.05 12 12.3 12 22.2"></polygon>
                </svg></span>
            </div>
          </div>
          <h3 class="">Hvorfor mig?</h3>
          <p class="text-muted mb-4">Du skal være tryg ved din forsvarer. Det skal være en som bekymrer sig om din sag, som ikke lader sig tryne af politiet og som er tilgængelig, når du har brug for det.</p>
          <!-- <a href="javascript: void(0);" class="btn btn-outline-primary">Learn More</a> -->
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-arm-flex"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Voldssager</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Vold, slåskamp, overfald, røveri og nødværge/selvforsvar.</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-trending-up"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Økonomisk kriminalitet</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Bedrageri, mandatsvig, underslæb og tyveri.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-gender-male-female"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Sædelighedssager</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Voldtægt, blufærdighedskrænkelse og andre sædelighedssager.</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-message-outline"></i>
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Injurier</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Injurier, anklager og digitale krænkelser.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us End -->
