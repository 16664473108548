<!-- Contact Us Start -->
<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Kontakt mig</h3>
          <p class="text-muted font-size-15">Du kan altid kontakte mig. Jeg vender tilbage snarest muligt.</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="custom-form mb-5 mb-lg-0">
          <div id="message">{{resultMessage}}</div>
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="name">Navn *</label>
                  <input id="name" type="text" class="form-control" autocomplete="off" formControlName="name" placeholder="Dit navn...">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input id="email" type="email" class="form-control" autocomplete="off" formControlName="email" placeholder="Din e-mailadresse...">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="email">Telefon</label>
                  <input id="email" type="text" class="form-control" autocomplete="off" formControlName="phone" placeholder="Dit telefonnummer...">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="comments">Besked *</label>
                  <textarea id="comments" rows="4" class="form-control" formControlName="message" placeholder="Din besked..."></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary">Send besked <i-feather class="icon-size-15 ml-2 icon"
                    name="send"></i-feather></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="contact-detail text-muted ml-lg-5">
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="mail"></i-feather> : <a href="mailto:ulvi@advodan.dk"><span>ulvi@advodan.dk</span></a>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="link"></i-feather> : <a href="http://www.vindberg.dk"><span>www.vindberg.dk</span></a>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="phone-call"></i-feather> : <a href="tel:31324938"><span>31 32 49 38</span></a>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-1" name="map-pin"></i-feather> : <a href="https://goo.gl/maps/qWJE9AUBgRycv9Jp8" target="_blank"><span>Algade 43, 4000 Roskilde</span></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->
