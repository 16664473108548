<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="appService.windowScroll()"
id="navbar">
<div class="container">
  <!-- LOGO -->
  <a class="navbar-brand text-primary" href="/">
    Ulrik Vindberg
    <!--<img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="24" />
    <img src="assets/images/logo-light.png" alt="" class="logo-light" height="24" />-->
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="appService.toggleMenu()">
    <i class="mdi mdi-menu"></i>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
      <li class="nav-item" [ngClass]="{'active':appService.currentSection === 'home'}">
        <a href="javascript: void(0);" [ngxScrollTo]="'#home'" class="nav-link">Start</a>
      </li>
      <li class="nav-item" [ngClass]="{'active':appService.currentSection === 'services'}">
        <a href="javascript: void(0);" [ngxScrollTo]="'#services'" class="nav-link">Sagstyper</a>
      </li>
      <li class="nav-item" [ngClass]="{'active':appService.currentSection === 'features'}">
        <a href="javascript: void(0);" [ngxScrollTo]="'#features'" class="nav-link">Vigtigt at vide</a>
      </li>
      <li class="nav-item" [ngClass]="{'active':appService.currentSection === 'contact'}">
        <a href="javascript: void(0);" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Kontakt</a>
      </li>
    </ul>
  </div>
</div>
</nav>
<!-- Navbar End -->
