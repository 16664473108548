<!-- Footer Start -->
<section class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-5">
          <!--<img src="assets/images/logo-light.png" alt="" class="" height="24">-->
          <a class="navbar-brand text-secondary" href="/">
            Ulrik Vindberg
          </a>
          <ul class="list-inline footer-social-icon-content my-4">
            <li class="list-inline-item mr-4"><a href="tel:31324938" class="footer-social-icon">
                <i-feather name="phone-call"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="mailto:ulvi@advodan.dk" class="footer-social-icon">
                <i-feather name="mail"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="http://www.facebook.com/ulrik.vindberg" target="_blank" class="footer-social-icon">
                <i-feather name="facebook"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="https://www.linkedin.com/in/ulrikvindberg/" target="_blank" class="footer-social-icon">
                <i-feather name="linkedin"></i-feather>
              </a></li>
            <li class="list-inline-item"><a href="https://twitter.com/UVindberg" target="_blank" class="footer-social-icon">
                <i-feather name="twitter"></i-feather>
              </a></li>
          </ul>
          <p class="text-white-50 my-4 font-size-15">En del af ADVODAN Roskilde.</p>
        </div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-4">Sagstyper</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="javascript: void(0);" [ngxScrollTo]="'#services'" class="footer-link">Voldssager</a></li>
              <li><a href="javascript: void(0);" [ngxScrollTo]="'#services'" class="footer-link">Sædelighedssager</a></li>
              <li><a href="javascript: void(0);" [ngxScrollTo]="'#services'" class="footer-link">Økonomisk kriminalitet</a></li>
              <li><a href="javascript: void(0);" [ngxScrollTo]="'#services'" class="footer-link">Injurier</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Videoer</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="https://www.youtube.com/watch?v=UUcFOAsm4aY" class="footer-link">Vigtigt at vide</a></li>
              <li><a href="https://www.youtube.com/watch?v=FgYFrEY_nPU" class="footer-link">3 gode råd</a></li>
              <li><a href="https://www.youtube.com/watch?v=G4k2VK3hFj8" class="footer-link">Hvad koster en forsvarer?</a></li>
              <li><a href="https://www.youtube.com/watch?v=y-6KJklm7D4" class="footer-link">Hvad skal jeg gøre?</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Øvrigt</h6>
              <ul class="list-unstyled footer-sub-menu">
                <li><a href="https://algade43.dk/html/juridisk" class="footer-link">Lovpligtig information</a></li>
                <li><a href="https://algade43.dk/html/gdpr_info" class="footer-link">Persondata - Oplysning</a></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Footer End -->
