<!-- Features Start -->
<section class="section bg-light feather-bg-img" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Vigtigt at vide</h3>
          <p class="text-muted font-size-15">De vigtigste spørgsmål og svar</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <video class="img-fluid d-block mx-auto" controls>
            <source src="assets/images/vindberg_3.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-circle-multiple h2 text-primary mr-1 align-middle"></i> Hvad koster det?</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Hvis jeg bliver beskikket som din <b>forsvarer</b> betaler <b>statskassen</b> mit salær</h3>
        <p class="text-muted font-size-15 mb-4">Du har krav på en beskikket forsvarer i næsten alle sager. Kun i mindre sager (bødesager) har du ikke krav på det. Statskassen betaler salæret til en beskikket forsvarer. Hvis du bliver frifundet, koster det ingenting. Hvis du bliver dømt, skal du betale tilbage til statskassen.</p>
        <p class="text-muted mb-2">
          <i class="mdi mdi-check icon-xs mr-1 text-secondary"></i> Du kan selv vælge din forsvarer
        </p>
        <p class="text-muted">
          <i class="mdi mdi-check icon-xs mr-1 text-secondary"></i> Du har næsten altid ret til at skifte forsvarer
        </p>
        <div class="mt-5">
          <!--<a href="javascript: void(0);" class="btn btn-primary mr-2">Read More</a>-->
          <a href="tel:31324938" class="btn btn-secondary">Ring nu</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-timer-outline h2 text-primary mr-1 align-middle"></i> Kontakt mig tidligst muligt</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Jo tidigere du kontakter mig, desto bedre kan jeg beskytte dig</h3>
        <p class="text-muted font-size-15 mb-4">Det er vigtigt at få en forsvarer tidligst muligt. Jeg er med dig under afhøringer, under efterforskningen og under retsmøderne.</p>
        <p class="text-muted mb-2">
          <i class="mdi mdi-check icon-xs mr-1 text-secondary"></i> Jeg sørger for at dine rettigheder bliver overholdt
        </p>
        <p class="text-muted">
          <i class="mdi mdi-check icon-xs mr-1 text-secondary"></i> Det bedste du kan gøre er at ringe tidligst muligt.
        </p>
        <div class="mt-5">
          <a href="tel:31324938" class="btn btn-secondary">Ring nu</a>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-4 mt-lg-0">
          <video muted id="bg_video" class="img-fluid d-block mx-auto" controls>
            <source src="assets/images/vindberg_2.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section bg-light feather-bg-img">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <!--<img src="assets/images/features-img.png" alt="" class="img-fluid d-block mx-auto">-->
          <video muted id="bg_video" class="img-fluid d-block mx-auto" controls>
            <source src="assets/images/vindberg_4.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>

      <div class="col-lg-5 offset-lg-1">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-clipboard-list-outline h2 text-primary mr-1 align-middle"></i> Hvad skal jeg gøre?</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">Du skal give politiet dit navn, adresse og fødselsdato. Derudover behøver du ikke sige noget til dem.</h3>
        <p class="text-muted font-size-15 mb-4">Hvis du bliver konaktet af politiet <u>skal</u> du oplyse dit navn, adresse og fødselsdato. Det er en god idé at vente med at sige andet, til jeg kan deltage. Husk:</p>
        <p class="text-muted mb-2">
          <i class="icon-xs mr-1 text-secondary">1.</i> Oplys politiet dit <b>navn, adresse og fødselsdato</b>
        </p>
        <p class="text-muted mb-2">
          <i class="icon-xs mr-1 text-secondary">2.</i> Oplys politiet at du ikke vil sige mere <b>før jeg ankommer</b>
        </p>
        <p class="text-muted mb-2">
          <i class="icon-xs mr-1 text-secondary">3.</i> <b> Ring til mig</b>
        </p>


        <div class="mt-5">
          <!--<a href="javascript: void(0);" class="btn btn-primary mr-2">Read More</a>-->
          <a href="tel:31324938" class="btn btn-secondary">Ring nu</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Features End -->
