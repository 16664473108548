import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: [''],
    phone: [''],
    message: ['', Validators.required]
  });
  resultMessage = '';

  constructor(
    private fb: FormBuilder,
    private http: HttpClient) { }

  ngOnInit(): void {

  }

  onSubmit(contactData: any): void {
    const data = JSON.stringify(contactData);
/*
    const apiUrl = 'http://www.vindberg.dk/api';
    this.http.post(`${apiUrl}/email.php`, data);
*/
    this.resultMessage = 'Beskeden er sendt';

    this.contactForm.reset();
  }

}
