  <app-menu></app-menu>

  <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="appService.onSectionChange($event)">

    <!-- Hero Start -->
    <section class="hero-3-bg" id="home">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-4 col-lg-5">
            <h1 class="hero-3-title text-shadow mb-0">Din sag, dit valg</h1>
            <p class="mt-4 mb-0">Du skal ikke nøjes med en offentlig forsvarer, der ikke har tid til dig. <span class="font-weight-bold">Du fortjener bedre</span>.</p>
            <p class="mt-4 mb-0"><span class="font-weight-bold">Tag kontrol over din sag</span> og <span class="font-weight-bold">vælg en forsvarer</span>, som du altid kan kontakte, som politiet ikke kan tryne og som kender spillet, så du kan <span class="font-weight-bold">føle dig tryg</span>.</p>
            <div class="mt-4 mb-5 pb-5">
              <a href="tel:31324938" class="btn btn-secondary text-uppercase">Ring nu</a>
            </div>
            <div class="watch-video mb-4 mb-lg-0">
              <a (click)="openWindowCustomClass(videocontent)" class="video-play-icon text-dark"><i
                  class="mdi mdi-play play-icon-circle text-dark mr-3"></i>
                <span class="icon">Se videoen</span>
              </a>
            </div>
          </div>
          <div class="col-xl-7 offset-xl-1 col-lg-7 col-md-10">
            <img src="assets/images/pic.jpg" alt="" class="img-fluid d-block mx-auto">
            <!--<video autoplay muted id="bg_video" class="img-fluid d-block mx-auto">
              <source src="assets/images/vindberg.mp4" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>-->
          </div>
        </div>
      </div>
    </section>
    <ng-template #videocontent let-modal class="video-modal">
      <div class="modal-header">
        <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>-->
      </div>
      <div class="modal-body p-0">
        <youtube-player [videoId]="'UUcFOAsm4aY'" height="450" width="780">
        </youtube-player>
      </div>
    </ng-template>
    <!-- Hero End -->

    <app-services></app-services>
    <app-features></app-features>
    <!--<app-pricing></app-pricing>-->
    <!--<app-blog></app-blog>-->
    <app-contact></app-contact>
    <app-footer></app-footer>
  </div>
